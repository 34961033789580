import Image, { ImageProps } from "next/image";
import styles from "./index.module.scss";
import React, { FC } from "react";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
interface IPicture extends ImageProps {
  desktop: string | StaticImport;
  tablet?: string;
  mobile: string;
  alt: string;
  width: number | `${number}`;
  height: number | `${number}`;
  className?: string;
  name?: string;
  src?: string;
}
const Picture: FC<IPicture> = props => {
  const {
    desktop = "",
    tablet = "",
    mobile = "",
    alt = "",
    width,
    height,
    className = "",
    name = "",
    ...rest
  } = props;
  return <picture className={styles.picture} data-sentry-element="picture" data-sentry-component="Picture" data-sentry-source-file="index.tsx">
      {mobile && <source media="(max-width: 767.98px)" srcSet={mobile} />}
      {tablet && <source media="(max-width: 1365.98px)" srcSet={tablet} />}
      <Image {...rest} src={desktop} alt={alt} width={width} height={height} className={className} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </picture>;
};
export default Picture;