"use client";

import Link from "next/link";
import styles from "./index.module.scss";
import { FC, useState } from "react";
import { ICatalogMiniItem } from "@/shared/types/types";
import { getNoun } from "@/utils/functions/getNoun";
import Picture from "@/shared/components/Picture";
import { rejectedCatalogs } from "@/shared/helpers/data/catalogHelpers";
interface ICatalogItemProps {
  item: ICatalogMiniItem;
}
const CatalogItem: FC<ICatalogItemProps> = props => {
  const {
    item
  } = props;
  const [more, setMore] = useState(3);
  const depths = item.depths ? Object.values(item.depths) : [];
  const handleClick = () => setMore(depths.length);
  const noun = getNoun(depths.length - more + 1, "категория", "категории", "категорий");
  const rejected = rejectedCatalogs.find(catalog => catalog === item.code);
  return <li className={styles.item} data-sentry-component="CatalogItem" data-sentry-source-file="index.tsx">
      <Link prefetch={false} href={`/catalog/${item.code}`} className={styles.link} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        {/*<Image*/}
        {/*  className={styles.img}*/}
        {/*  alt={item.Name}*/}
        {/*  src={`https://ohotaktiv.ru${item.catalog_dickpic}`}*/}
        {/*  width={1000}*/}
        {/*  height={1000}*/}
        {/*/>*/}
        <Picture className={styles.img} desktop={`https://ohotaktiv.ru${item.page_desktop_picture}`} mobile={`https://ohotaktiv.ru${item.page_picture}`} width={200} height={200} alt={item.name} data-sentry-element="Picture" data-sentry-source-file="index.tsx" />
        {!rejected ? <p className={styles.name}>{item.name}</p> : null}
      </Link>
      {depths.length > 0 && <ul className={styles.depthList}>
          {depths.map((child, index) => {
        if (index >= more) return null;
        return <li key={`${index + "li" + "catalogItem" + "depths"}`} className={styles.depthItem}>
                <Link prefetch={false} href={`/catalog/${child.code}`} className={styles.depthLink}>
                  {child.name}
                </Link>
              </li>;
      })}
          {depths.length > more && <li className={styles.depthItem}>
              <button onClick={handleClick} className={styles.more}>
                Ещё {depths.length - more} {noun}
              </button>
            </li>}
        </ul>}
    </li>;
};
export default CatalogItem;