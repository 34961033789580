export const rejectedCatalogs = ["full_remington"];

export const emptySectionInfo = {
  info: {
    uf_top_description: "",
    description: "",
    uf_is_landing: "",
    name: "",
    id: "",
  },
  banners: {
    banner: "",
    bannerMob: "",
  },
  tags: [],
  categories: [],
};

export const defaultFilters = ["sortby", "page", "price_from", "price_to", "stock"];

export const catalogBrandsBP = {
  1439: {
    spaceBetween: 32,
    slidesPerView: 5,
  },
  1365: {
    spaceBetween: 32,
    slidesPerView: 5,
  },
  1023: {
    spaceBetween: 16,
    slidesPerView: 4,
  },
  767: {
    spaceBetween: 16,
    slidesPerView: 3,
  },
  320: {
    spaceBetween: 10,
    slidesPerView: 3,
  },
};
